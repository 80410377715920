import React from 'react';

export default function Partners() {
    const logos = [
        "/cinect.png",
        "/Kanema-online.png",
        "/katswiri.png",
        "/mingoli.png",
        "/Nkhani.png",
        "/venda.png",
    ];

    return (
        <div className='w-full flex flex-col items-center justify-center my-6' id='partners'>
            <p className='text-black text-xl text-center font-normal py-4'>Our Partners</p>
            <div className='w-full overflow-hidden'>
                <div className='flex animate-scroll-mobile md:animate-scroll whitespace-nowrap'>
                    {logos.map((logo, index) => (
                        <img
                            key={index}
                            src={logo}
                            alt={`Partner logo ${index}`}
                            className='h-8 w-auto mx-2'
                        />
                    ))}
                    {logos.map((logo, index) => (
                        <img
                            key={index + logos.length}
                            src={logo}
                            alt={`Partner logo duplicate ${index}`}
                            className='h-8 w-auto mx-2'
                        />
                    ))}
                    {logos.map((logo, index) => (
                        <img
                            key={index + logos.length}
                            src={logo}
                            alt={`Partner logo duplicate ${index}`}
                            className='h-8 w-auto mx-2'
                        />
                    ))}
                    {logos.map((logo, index) => (
                        <img
                            key={index + logos.length}
                            src={logo}
                            alt={`Partner logo duplicate ${index}`}
                            className='h-8 w-auto mx-2'
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}
