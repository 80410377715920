import { React } from 'react'

export default function Steps() {
    return (
        <div className='flex flex-col items-center justify-center rounded-3xl bg-gradient-to-t from-[#ceb9eb] to-white w-full md:w-[40vh] px-4'>

            <h1 className='text-2xl text-black text-center font-semibold py-4'>Open Wi-Fi settings and select TONSENET.</h1>

            <img src='/WiFi-1.png' alt='wifi' className='w-full h-[38vh]' />

        </div>
    )
}