import {React} from 'react'
import Navbar from '../components/navbar'
import HeroSection from '../components/hero_section'
import Partners from '../components/partners'
import HowItWorks from '../components/how_it_works'
import FAQ from '../components/faq'

export default function Home() {
    return (
        <div className='w-full md:w-[1200px] mx-auto'>
            <Navbar />
            <HeroSection />
            <Partners />
            <HowItWorks />
            {/* <FAQ /> */}
        </div>
    )
}