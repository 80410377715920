import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState("");


    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


    return (
        <nav className={`w-[95%] md:w-fit px-4 bg-white bg-opacity-95 md:border md:border-grey md:shadow-lg ${isOpen ? 'rounded-xl' : 'rounded-full'} mx-auto my-4 py-1 font-inter sticky top-0 z-50`}>
            <div className="flex justify-between w-full md:w-[900px] items-center h-16">
                {/* Logo Section */}
                <div>
                    <a href='#'>
                        <div className="w-34 h-24 md:mr-6">
                            <img src='/Tonse-Net.png' alt="Logo" className="object-contain w-full h-full" />
                        </div>
                    </a>
                </div>

                {/* Menu Button (Mobile) */}
                <button onClick={toggleMenu} className="ml-auto sm:hidden text-blue-800">
                    {isOpen ? (
                        <IoMdClose size={24} />
                    ) : (
                        <FaBars size={24} />
                    )}
                </button>

                {/* Nav Links (Larger Screens) */}
                <div>
                    <ul className="hidden sm:flex space-x-6 text-sm text-black font-light">
                        <li>
                            <a href="#works" onClick={() => setActiveTab("works")}>
                                <div className={`rounded-full px-2 py-1 ${activeTab === "works" ? "font-bold" : "bg-none"}`}>
                                    How It Works
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#" onClick={() => setActiveTab("features")}>
                                <div className={`rounded-full px-2 py-1 ${activeTab === "features" ? "font-bold" : "bg-none"}`}>
                                    Features
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#" onClick={() => setActiveTab("available")}>
                                <div className={`rounded-full px-2 py-1 ${activeTab === "available" ? "font-bold" : "bg-none"}`}>
                                    Availability Map
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#partners" onClick={() => setActiveTab("partners")}>
                                <div className={`rounded-full px-2 py-1 ${activeTab === "partners" ? "font-bold" : "bg-none"}`}>
                                    Partners
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#faq" onClick={() => setActiveTab("faq")}>
                                <div className={`rounded-full px-2 py-1 ${activeTab === "faq" ? "font-bold" : "bg-none"}`}>
                                    Testimonials
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>

                {/* Download Button (Larger Screens) */}
                <a href='#works'>
                <div className='hidden sm:flex bg-blue-800 rounded-full py-3 px-4 my-3 font-light text-md hover:scale-105 text-white cursor-pointer'>
                    Get Started
                </div>
                </a>
            </div>

            {/* Mobile nav */}
            {isOpen && (
                <div className="sm:hidden mt-4">
                    <ul className="flex flex-col space-y-4 text-black font-normal text-center">
                        <a href="#">
                            <li className="hover:font-bold flex justify-center">
                                Features
                            </li>
                        </a>
                        <a href="#works">
                            <li className="hover:font-bold flex justify-center">
                                How It Works
                            </li>
                        </a>
                        <a href="#">
                            <li className="hover:font-bold flex justify-center">
                                Availability Map
                            </li>
                        </a>
                        <a href="#partners">
                            <li className="hover:font-bold flex justify-center">
                                Partners
                            </li>
                        </a>
                        <a href="#">
                            <li className="hover:font-bold flex justify-center">
                                Testimonials
                            </li>
                        </a>
                        <li>
                            <a href="#works">
                                <div className='bg-blue-800 text-white rounded-full py-2 px-12 my-3 font-semibold text-lg hover:scale-105 w-fit mx-auto'>Get Started</div>
                            </a>
                        </li>
                    </ul>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
