import {React} from 'react'

export default function HeroSection() {
    return (
        <div className='relative w-full px-2 mx-auto'>
            <img src='/hero-bg.png' alt="Hero Section" className="w-full h-[50vh] rounded-3xl object-cover" />
            <div className="absolute inset-0 flex flex-col items-center justify-center">
                <h1 className="text-black w-[40vh] md:w-full text-4xl md:text-6xl text-center font-bold">FREE INTERNET FOR ALL</h1>
                <p className='text-black text-md md:text-xl font-normal md:font-light px-4 py-4 text-center'>
                    Available in malls, shopping centers, and public spaces in Malawi. Browse, Work, or Stream without Interruptions
                </p>
                <div className='flex items-center justify-center gap-x-4 md:gap-x-8'>
                    <a href='#works'>
                    <div className='w-fit py-1 md:py-3 px-3 md:px-6 bg-black rounded-full text-white text-lg font-light'>
                        Get Started
                    </div>
                    </a>
                    <a href='#'>
                    <div className='w-fit py-1 md:py-3 px-3 md:px-6 bg-transparent border border-black rounded-full text-black text-lg font-light'>
                        Find a Location
                    </div>
                    </a>
                </div>
            </div>
        </div>
    )
}
