import { React } from 'react'
import { IoHelpCircleSharp } from "react-icons/io5";
import Steps from './How It Works/steps_container';

export default function HowItWorks() {
    return (
        <div className='flex flex-col items-start justify-center my-14' id='works'>
            <div className='flex items-center justify-center gap-x-1 mx-auto'>
                <IoHelpCircleSharp size={32} />
                <p className='text-black text-md font-light'>How it works</p>
            </div>
            <h1 className='text-3xl md:text-5xl text-black font-bold py-4 text-center mx-auto'>Getting Started With</h1>
            <h1 className='text-4xl md:text-5xl text-blue-800 font-bold text-center mx-auto'>TonseNet</h1>

            <p className='text-black text-lg mdtext-xl font-light py-4 w-[42vh] text-center mx-auto'>Follow these simple steps to set up and start connecting today</p>

            <div className='flex items-center justify-center mx-auto md:mx-0 md:justify-start py-4 px-3 md:px-0'>
                <Steps />
            </div>
        </div>
    )
}